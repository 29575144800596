<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>

    <base-material-card
      icon="mdi-cog"
      title="Optional Fields List"
      class="px-5 py-3"
    >
      <v-simple-table>

        <tbody>
        <tr v-for="optionalField in optionalFields">
          <td>{{ $t(optionalField.name) }}</td>
          <td>
            <div class="d-inline-flex">
              <v-btn v-if="!optionalField.selected" color="ma-2 info float-right" fab x-small dark
                     v-on:click="addOptionalField(optionalField.id)">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
              <v-btn v-if="optionalField.selected" color="ma-2 red float-right" fab x-small dark
                     v-on:click="deleteOptionalField(optionalField.id)">
                <v-icon dark>mdi-minus</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3"/>
  </v-container>
</template>

<script>
  import VValidationErrors from '../../components/base/VValidationErrors'

  export default {
    name: 'optional_fields',
    components: {
      VValidationErrors,
    },
    watch: {
      $route () {
        this.getOptionalFields()
      },
    },
    data () {
      return {
        showAdd: true,
        showDelete: false,
        optionalFields: [],
        validationErrors: {},
      }
    },
    created () {
      this.getOptionalFields()
    },
    methods: {
      getOptionalFields () {
        this.$authApi.get('/optional-fields').then(res => {
          this.optionalFields = res.data.data
        })
      },
      addOptionalField (id) {
        this.$authApi.post('/partner-optional-fields', {
          optional_field_id: id,
        }).then(res => {
          this.flashMessage.success({
            title: 'Added  Successfully',
            message: `Optional Field Added Successfully`,
          })
          this.getOptionalFields()
        }).catch(error => {
          if (error.response) {
            this.validationErrors = error.response.data.errors
          }
        })
      },
      deleteOptionalField (id) {
        this.$authApi.delete(`/partner-optional-fields/${id}`).then(res => {
          this.getOptionalFields()
        })
      },
    },
  }
</script>

<style scoped>

</style>
